import { render, staticRenderFns } from "./QuestionDrawerComplaint.vue?vue&type=template&id=d8af6288&scoped=true&"
import script from "./QuestionDrawerComplaint.vue?vue&type=script&lang=js&"
export * from "./QuestionDrawerComplaint.vue?vue&type=script&lang=js&"
import style0 from "./QuestionDrawerComplaint.vue?vue&type=style&index=0&id=d8af6288&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8af6288",
  null
  
)

export default component.exports