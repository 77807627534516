import truncate from '@/utils/truncate'

export default {
  props: {
    values: {
      type: Object,
      validator: (value) => value.breakpoint && value.length,
    },
  },
  computed: {
    defaultTextMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 45
        case 'small':
          return 38
        case 'medium':
          return 52
        case 'large':
          return 62
        case 'x_large':
          return 82
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: {
    truncateText(title = '', titleMaxLength) {
      return truncate(title, titleMaxLength || this.defaultTextMaxLength)
    },
  },
}
