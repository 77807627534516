<template>
  <div class="question-drawer-details">
    <div class="question-drawer-details__visible">
      <div
        v-if="mustShowSubject"
        class="question-drawer-details__visible__content"
      >
        <template v-if="loading">
          <skeleton-loader
            class="question-drawer-details__label"
            width="70px"
            height="15px"
          />
          <skeleton-loader
            width="120px"
            height="22px"
          />
        </template>
        <template v-else>
          <span class="question-drawer-details__label">
            {{ $t('teacher.report.questionsDrawer.contents') }}
          </span>
          <p>{{ content }}</p>
        </template>
      </div>
      <div
        v-if="question.level"
        class="question-drawer-details__visible__level"
      >
        <template v-if="loading">
          <skeleton-loader
            class="question-drawer-details__label"
            width="70px"
            height="15px"
          />
          <skeleton-loader
            width="60px"
            height="22px"
          />
        </template>
        <template v-else>
          <span class="question-drawer-details__label">
            {{ $t('teacher.report.questionsDrawer.level') }}
          </span>
          <p>
            {{ $t(
              `levels.${question.level}`
            ) }}
          </p>
        </template>
      </div>
      <s-button
        v-if="mq_m && hasMoreDetails && !loading"
        class="question-drawer-details__visible__toggle"
        variation="tertiary"
        :icon="toggle ? 'chevrons-up' : 'chevrons-down'"
        :active="toggle"
        @click="toggle = !toggle"
      />
      <s-button
        v-if="mq_l__mf && hasMoreDetails && !loading"
        variation="tertiary"
        :icon-right="toggle ? 'chevrons-up' : 'chevrons-down'"
        :active="toggle"
        @click="toggle = !toggle"
      >
        {{ $t(toggle ? 'commons.lessDetails' : 'commons.moreDetails') }}
      </s-button>
    </div>
    <div
      v-show="toggle"
      class="question-drawer-details__collapsible"
    >
      <div class="question-drawer-details__collapsible__object">
        <span class="question-drawer-details__label">
          {{ $t('teacher.report.questionsDrawer.skillContent') }}
        </span>
        <p>{{ skillContent }} </p>
      </div>
      <div class="question-drawer-details__collapsible__skill">
        <span class="question-drawer-details__label">
          {{ $t('teacher.report.questionsDrawer.skill') }}
        </span>
        <p>{{ skill }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import subjectVisibilityToggle from '@/mixins/subjectVisibilityToggle'

export default {
  name: 'QuestionDrawerDetails',
  mixins: [ mediaQueries, subjectVisibilityToggle ],
  props: {
    question: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      toggle: false,
    }
  },
  computed: {
    content() {
      return this.question.content
        ? this.question.content.name
        : '-'
    },
    hasMoreDetails() {
      return Boolean(this.question.skill)
    },
    skillContent() {
      return this.question.skill
        && this.question.skill.content
        ? this.question.skill.content.name
        : '-'
    },
    skill() {
      return this.question.skill
        ? this.question.skill.name
        : '-'
    },
  },
  methods: {
    toggleDetails() {
      this.toggle = !this.toggle
    },
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="sass" scoped>
.question-drawer-details
  background: linear-gradient(180deg, rgba(0,0,0, 0.1) 0, rgba(0,0,0,0) 3px), linear-gradient(0deg, rgba(0,0,0, 0.15) 0, $color-ice 2px)
  padding: $size-m $size-l
  position: relative

  ::v-deep .sas-button.--active
    background: rgba($color-ink, .25)

  &__label
    color: $color-ink-light
    font-size: 11px
    text-transform: uppercase
    letter-spacing: 1.3px
    display: inline-block
    margin-bottom: $size-xxs

  &__visible
    +mq-m--mf
      +flex-center-start

    &__content
      margin-bottom: $size-s

      +mq-m--mf
        margin-right: $size-xxl

    &__level
      margin-bottom: $size-s

      +mq-m--mf
        flex-grow: 1

    &__toggle
      position: absolute
      top: $size-xs
      right: $size-xs

  &__collapsible
    overflow: hidden

    +mq-m--mf
      padding-top: $size-l

    &__object
      margin-bottom: $size-s
      +mq-m--mf
        margin-bottom: $size-l
</style>
<!-- eslint-enable max-len -->
