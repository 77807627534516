<template>
  <box
    :class="[
      'questions-card',
      `--${variation}`,
      { '--active': active }
    ]"
    elevation="2"
    elevation-hover="3"
    padding="0"
    @click.native="$emit('click')"
  >
    <div class="questions-card__wrapper">
      <div
        class="questions-card__number"
      >
        <span>{{ order }}</span>
      </div>
      <div class="questions-card__text">
        <h6 class="questions-card__statement">
          {{ truncatedStatement }}
        </h6>
        <span
          v-if="mustShowSubject"
          class="questions-card__content"
        >
          {{ truncatedContentName }}
        </span>
      </div>
      <s-badge
        class="questions-card__hit"
        :icon="badgeIcon(correct)"
        :variation="badgeVariation(correct)"
      />
    </div>
  </box>
</template>

<script>
import truncateText from '@/mixins/truncateText'
import badgeStyle from '@/mixins/badgeStyle'
import subjectVisibilityToggle from '@/mixins/subjectVisibilityToggle'

export default {
  name: 'QuestionsCard',
  mixins: [ badgeStyle, truncateText, subjectVisibilityToggle ],
  props: {
    order: {
      type: Number,
      required: true,
    },
    statement: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    correct: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    variation: {
      type: String,
      default: 'neutral',
      validator: (value) => value.match(/(neutral|warning)/),
    },
  },
  computed: {
    truncatedStatement() {
      const { statement, statementMaxLength } = this

      return this.truncateText(statement, statementMaxLength)
    },
    truncatedContentName() {
      const { contentName, contentMaxLength } = this

      return this.truncateText(contentName, contentMaxLength)
    },
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 44
        case 'small':
          return 50
        case 'medium':
          return 52
        case 'large':
          return 64
        case 'x_large':
          return 80
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
    contentMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 20
        case 'small':
          return 28
        case 'medium':
          return 60
        case 'large':
          return 70
        case 'x_large':
          return 80
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
    contentName() {
      return this.content?.name ?? ' '
    },
  },
}
</script>

<style lang="sass" scoped>
.questions-card
  +space-stack($size-s)
  cursor: pointer

  &__wrapper
    +flex-center
    padding: $size-s
    border-radius: $size-xs
    +transition(200ms)

    .--warning &
      box-shadow: 0 0 0 2px #794C00

    .--active &
      box-shadow: inset 0 0 0 2px $color-primary
      background-color: $color-primary-lightest

  &__text
    flex-grow: 1

  &__number
    background: transparentize($color-ink-lightest, 0.5)
    border-radius: $border-radius-m
    display: block
    height: $size-m
    width: $size-m
    margin-right: $size-s
    flex-shrink: 0
    +flex-center

    span
      font-weight: 900
      color: $color-ink-light

    .--active &
      background-color: $color-primary

      span
        color: $color-white

    .--warning &
      background-color: $color-warning-lightest

      span
        color: $color-warning-darkest

  &__statement
    margin-bottom: $size-xxs

  &__content
    color: $color-ink-light

  &__hit
    margin-left: $size-s
    flex-shrink: 0
</style>
