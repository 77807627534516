<template>
  <div class="questions">
    <box
      v-for="index in 5"
      :key="index"
      class="questions__item"
      elevation="2"
      elevation-hover="3"
      padding="0"
    >
      <div
        class="questions__item__number"
      >
        <skeleton-loader
          :width="$tokens.size_m"
          :height="$tokens.size_m"
          :border-radius="$tokens.border_radius_m"
        />
      </div>
      <div class="questions__item__text">
        <div class="questions__item__statement">
          <skeleton-loader
            width="90%"
            height="25px"
          />
        </div>
        <span class="questions__item__content">
          <skeleton-loader
            width="50%"
            height="19px"
          />
        </span>
      </div>
      <p
        v-if="mq_l__mf"
        class="questions__item__level"
      >
        <skeleton-loader
          width="60px"
          height="22px"
        />
      </p>
      <skeleton-loader
        border-radius="28px"
        width="28px"
        height="28px"
      />
    </box>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'QuestionsSkeleton',
  mixins: [ mediaQueries ],
}
</script>

<style lang="sass" scoped>
.questions
  &__item
    +flex-center
    +space-stack($size-s)
    padding: $size-s $size-s !important

    &__text
      flex-grow: 1

    &__number
      display: block
      margin-right: $size-s
      flex-shrink: 0
      +flex-center

    &__statement
      margin-bottom: $size-xxs

    &__level
      margin-left: $size-xxl
      margin-right: $size-xxl
      width: $size-xxl
      flex-shrink: 0

    &__hit
      margin-left: $size-s
      flex-shrink: 0
</style>
