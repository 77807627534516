<template>
  <s-button
    class="satisfaction-survey-button"
    variation="tertiary"
    icon-left="star"
    @click="openFeedbackSurvey"
  >
    {{ $t('survey.rateYourExperience') }}
  </s-button>
</template>

<script>
import { mapGetters } from 'vuex'

import mediaQueries from '@/mixins/mediaQueries'
import typeform from '@/service/typeform'
import urls from '@/utils/urls'

export default {
  name: 'SatisfactionSurvey',
  mixins: [ mediaQueries ],
  computed: {
    ...mapGetters([
      'subject',
      'user',
      'report',
    ]),
  },
  methods: {
    openFeedbackSurvey() {
      const appBaseUrl = urls.BASE_URL
      const formId = 'R0J8qRJQ'
      const url = appBaseUrl.concat(this.$route.path)
      const accountId = this.user.id
      const grade = this.user.grades[0].name
      const lecture = this.report.data.lectureName
      const chapterTitle = this.report.data.title
      const chapterNumber = this.report.data.chapterOrder
      const platform = 'web'
      const acertos = this.report.data.correctAnswers
      const type = this.report.data.questionnaireType

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields: {
          acertos,
          account_id: accountId,
          chapter_title: chapterTitle,
          chapter_number: chapterNumber,
          grade,
          lecture,
          platform,
          url,
          type,
        },
      })

      typeformPopup.open()
      this.$trackEvent({
        category: this.$track.category.feedback,
        action: this.$track.action.rateExperience,
      })
    },
  },
}
</script>
