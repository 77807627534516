<template>
  <div class="reported-questions">
    <QuestionsCard
      v-for="(question, index) in questions"
      :key="index"
      :order="question.position"
      :statement="question.statement"
      :content="question.content"
      :correct="question.correct"
      :active="question === activeQuestion"
      :variation="variation"
      @click="$emit('select', {
        question,
        questionIndex: index
      })"
    />
  </div>
</template>

<script>
import QuestionsCard from './QuestionsCard'

export default {
  name: 'ReportedQuestions',
  components: {
    QuestionsCard,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    activeQuestion: {
      type: Object,
      default: null,
    },
    variation: {
      type: String,
      default: 'neutral',
    },
  },
}
</script>
