<template>
  <div class="hit-rate">
    <box
      padding="0"
      :border-radius="mq_m ? '0' : $tokens.border_radius_m"
    >
      <div class="hit-rate__body">
        <div class="hit-rate__chart">
          <RadialProgressSkeleton
            :size="`${donutSize}px`"
            border-radius="50%"
          />
        </div>
        <div class="hit-rate__text">
          <div class="hit-rate__text__title">
            <skeleton-loader
              width="100%"
              height="38px"
            />
          </div>
          <div class="hit-rate__text__count">
            <skeleton-loader
              width="50%"
              height="22px"
            />
          </div>
        </div>
      </div>
    </box>
  </div>
</template>

<script>
import RadialProgressSkeleton from '@/components/RadialProgressSkeleton'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'HitRateSkeleton',
  components: {
    RadialProgressSkeleton,
  },
  mixins: [ mediaQueries ],
  computed: {
    donutSize() {
      if (this.mq_s) return 90
      if (this.mq_m) return 110
      if (this.mq_l) return 120

      return 140
    },
  },
}
</script>

<style lang="sass" scoped>
.hit-rate
  &__body
    padding: $size-m $size-s
    +flex-center-start

    +mq-xl__mf
      padding: $size-l
      +flex-column-center

  &__chart
    margin-right: $size-s

    +mq-m__mf
      margin-right: $size-l

    +mq-xl__mf
      margin-right: 0
      margin-bottom: $size-m

  &__text
    width: 220px
    max-width: 100%
    &__title
      margin-bottom: $size-xs
</style>
