export default {
  methods: {
    badgeIcon(correct) {
      return correct
        ? 'check'
        : 'x'
    },
    badgeVariation(correct) {
      return correct
        ? 'success'
        : 'danger'
    },
  },
}
