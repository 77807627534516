<template>
  <div class="question-drawer-complaint">
    <template>
      <header class="question-drawer-complaint__header">
        <s-badge
          variation="warning"
          icon-left="flag"
        >
          {{ $t('questions.reportAProblem.youReportedAProblem') }}
        </s-badge>
      </header>
      <div class="question-drawer-complaint__body">
        <div class="question-drawer-complaint__body__complaint-filters">
          <span>
            {{ $t('questions.reportAProblem.reasons').toUpperCase() }}
          </span>
          <p class="question-drawer-complaint__body__complaints">
            {{ formattedComplaints }}
          </p>
        </div>
        <div class="question-drawer-complaint__body__complaint-description">
          <p>{{ complaint.description }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'QuestionDrawerComplaint',
  mixins: [ mediaQueries ],
  props: {
    complaint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([ 'user' ]),
    formattedComplaints() {
      return this.complaint.complaintTypes.map(
        (type) => this.$t(`questions.reportAProblem.complaintTypes.${type}`)
      ).join(' / ')
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-complaint
  padding: $size-m
  border-bottom: 1px solid $color-ink-lightest

  &__header
    margin-bottom: $size-s

    .sas-badge
      color: #794C00

  &__body
    &__complaint-filters
      margin-bottom: $size-m

      span:first-child
        display: block
        color: $color-ink-light
        margin-bottom: $size-xxs

      p
        font-weight: 500
</style>
