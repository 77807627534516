<template>
  <drawer
    class="question-drawer"
    :background="$tokens.color_white"
    :overlay-enabled="false"
    :size="openQuestionProblems && canShowProblems
      ? '100%' : $tokens.device_m"
  >
    <DrawerNavigator
      :has-next="hasNext"
      :has-previous="hasPrevious"
      :disabled-report="reportIsDisabled"
      :hide-more-options="hideReport"
      @report-succeed="updateQuestionDetails"
      @previous="$emit('on-previous')"
      @next="$emit('on-next')"
      @close="$emit('on-close')"
    >
      <template #header>
        <skeleton-loader
          v-if="loading"
          width="120px"
          height="33px"
        />
        <div
          v-else
          class="question-drawer__header"
        >
          <h4 class="question-drawer__header__title">
            {{ $t(
              'teacher.report.questionsDrawer.questionNumber',
              { number: question.number }
            ) }}
          </h4>
          <template v-if="question.correct !== undefined">
            <s-badge
              :icon="badgeIcon(question.correct)"
              :variation="badgeVariation(question.correct)"
            />
            <small
              :class="[
                'question-drawer__header__hit',
                `--${resultModifier}`
              ]"
            >
              {{ $t(`teacher.report.questionsDrawer.${resultLabel}`) }}
            </small>
          </template>
        </div>
      </template>
      <template #child>
        <QuestionDrawerDetails
          :question="questionWithDetails"
          :loading="loading"
        />
        <QuestionDrawerBodySkeleton v-if="loading" />
        <template v-else>
          <QuestionDrawerComplaint
            v-if="hasComplaint"
            :complaint="complaintsData"
            @show-problems="showDrawerProblems"
          />
          <QuestionDrawerBody
            :question="questionWithDetails"
          />
        </template>
      </template>
    </DrawerNavigator>
    <QuestionDrawerProblems
      v-if="openQuestionProblems && canShowProblems"
      :complaint="questionComplaints.data"
      :loading="questionComplaints.loading"
      :hide-report="hideReport"
      @close="hideDrawerProblems"
    />
  </drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import updateRouteQuery from '@/mixins/updateRoute'
import questions from '@/service/questions'
import mediaQueries from '@/mixins/mediaQueries'
import badgeStyle from '@/mixins/badgeStyle'
import keyEvents from '@/mixins/keyEvents'
import { isNotEmpty } from '@/utils/arrays'

import QuestionDrawerProblems from './ProblemsDrawer/QuestionDrawerProblems'
import QuestionDrawerBody from './QuestionDrawerBody'
import QuestionDrawerBodySkeleton from './QuestionDrawerBodySkeleton'
import QuestionDrawerDetails from './QuestionDrawerDetails'
import QuestionDrawerComplaint from './QuestionDrawerComplaint'
import DrawerNavigator from './DrawerNavigator'

export default {
  name: 'QuestionDrawer',
  components: {
    QuestionDrawerDetails,
    QuestionDrawerBody,
    QuestionDrawerBodySkeleton,
    DrawerNavigator,
    QuestionDrawerComplaint,
    QuestionDrawerProblems,
  },
  mixins: [ mediaQueries, badgeStyle, keyEvents, updateRouteQuery ],
  props: {
    question: {
      type: Object,
      default: null,
    },
    assignmentCode: {
      type: String,
      required: false,
      default: '',
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    disabledReport: {
      type: Boolean,
      default: false,
    },
    hideReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionDetails: {
        data: {},
        loading: false,
        error: null,
      },
      showProblemsReported: false,
      canDispatchUpdateEvent: false,
    }
  },
  computed: {
    ...mapGetters([ 'questionComplaints', 'complaint' ]),
    resultLabel() {
      return this.question.correct
        ? 'hit'
        : 'missed'
    },
    resultModifier() {
      return this.question.correct
        ? 'success'
        : 'danger'
    },
    questionWithDetails() {
      return {
        ...this.question,
        ...this.questionDetails.data,
      }
    },
    loading() {
      return this.questionDetails.loading
      || this.questionComplaints.loading
      || !this.question.id
    },
    hasComplaint() {
      const { complaintsData } = this

      if (complaintsData === null) {
        return false
      }

      return isNotEmpty(Object.keys(complaintsData))
    },
    isTeacher() {
      return Boolean(this.$route?.params?.classroomId)
    },
    complaintsData() {
      const { isTeacher, questionComplaints, questionDetails } = this

      if (isTeacher) {
        return questionComplaints.data
      }

      return questionDetails.data.questionComplaint
    },
    openQuestionProblems() {
      return this.$route?.query?.openQuestionProblems === 'true'
    },
    canShowProblems() {
      return !this.questionComplaints.error
    },
    userIsQuestionComplaintAuthor() {
      const { questionComplaint } = this.questionDetails.data

      return questionComplaint !== null
    },
    reportIsDisabled() {
      const {
        disabledReport,
        userIsQuestionComplaintAuthor,
        complaint,
      } = this

      return disabledReport
      || userIsQuestionComplaintAuthor
      || complaint.loading
    },
  },
  watch: {
    question() {
      this.fetchQuestionDetails()
      if (this.$route.params.classroomId) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      }
    },
  },
  created() {
    document.addEventListener('keyup', this.pressEsc, false)
    if (this.question.id) {
      this.fetchQuestionDetails()

      if (this.$route.params.classroomId) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      }
    }
  },
  beforeDestroy() {
    if (this.canDispatchUpdateEvent) {
      this.$emit('update-reports')
    }
  },
  destroyed() {
    document.removeEventListener('keyup', this.pressEsc, false)
  },
  methods: {
    ...mapActions([ 'getQuestionComplaints' ]),
    async fetchQuestionDetails() {
      this.questionDetails.loading = true
      const beforeRequestQuestionId = this.question.id
      try {
        const questionDetails = this.assignmentCode
          ? await questions.fetchAnsweredQuestion(
            this.question.id,
            this.assignmentCode
          )
          : await questions.fetchAnsweredQuestion(
            this.question.id
          )
        if (this.question.id === beforeRequestQuestionId) {
          this.questionDetails.data = questionDetails
        }
      } catch (error) {
        this.questionDetails.error = error.response
      } finally {
        this.questionDetails.loading = false
      }
    },
    pressEsc(event) {
      if (this.isEscKeyPressed(event)) {
        this.$emit('on-close')
      }
    },
    showDrawerProblems() {
      this.updateRouteQuery('openQuestionProblems', 'true')
    },
    hideDrawerProblems() {
      this.updateRouteQuery('openQuestionProblems', 'false')
    },
    updateQuestionDetails() {
      if (this.isTeacher) {
        this.getQuestionComplaints({
          questionId: this.question.id,
          classroomId: this.$route.params.classroomId,
        })
      } else {
        this.fetchQuestionDetails()
      }
      this.canDispatchUpdateEvent = true
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer
  ::v-deep .drawer
    overflow: visible
    overflow-y: auto

  &__header
    +flex-center-start
    width: 100%

    .sas-badge
      margin-left: $size-s

    &__hit
      display: none
      margin-left: $size-xs
      font-size: $font-size-s

      +mq-s--mf
        display: inline

      &.--success
        color: $color-success-dark

      &.--danger
        color: $color-danger-dark
</style>
