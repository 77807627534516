<template>
  <div class="hit-rate">
    <box
      padding="0"
      :border-radius="mq_m ? '0' : $tokens.border_radius_m"
    >
      <div class="hit-rate__body">
        <div class="hit-rate__chart">
          <radial-progress-bar
            inner-stroke-color="#EFF0F0"
            start-color="#438DE4"
            stop-color="#438DE4"
            :stroke-width="donutStroke"
            :diameter="donutSize"
            :total-steps="100"
            :completed-steps="hitRate"
          >
            <h4><b>{{ hitRate }}%</b></h4>
          </radial-progress-bar>
        </div>
        <div class="hit-rate__text">
          <h3 class="hit-rate__text__title">
            {{ $t('studentReport.assignmentHits') }}
          </h3>
          <p class="hit-rate__text__count">
            {{ $tc('studentReport.hitsCount',
                   total,
                   {
                     count: correct,
                     total
                   }
            ) }}
          </p>
        </div>
      </div>
    </box>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'HitRate',
  components: {
    RadialProgressBar,
  },
  mixins: [ mediaQueries ],
  props: {
    hitRate: {
      type: Number,
      default: 0,
    },
    correct: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    donutSize() {
      if (this.mq_s) return 90
      if (this.mq_m) return 110
      if (this.mq_l) return 120

      return 140
    },
    donutStroke() {
      if (this.mq_s) return 8
      if (this.mq_l) return 10

      return 12
    },
  },
}
</script>

<style lang="sass" scoped>
.hit-rate
  &__body
    padding: $size-m $size-s
    +flex-center-start

    +mq-xl__mf
      padding: $size-l
      +flex-column-center

  &__chart
    margin-right: $size-s

    +mq-m__mf
      margin-right: $size-l

    +mq-xl__mf
      margin-right: 0
      margin-bottom: $size-m

  &__text
    &__title
      margin-bottom: $size-xs
      white-space: nowrap

    &__count
      color: $color-ink-light
</style>
