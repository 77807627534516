<template>
  <div class="problem-picker">
    <AdaptiveSelectorList
      :options="options"
      :selected-option="selectedOption"
      @select="selectOption($event)"
    >
      <template #option="{ option }">
        <div class="problem-picker__item">
          {{ option.label }}
        </div>
      </template>
    </AdaptiveSelectorList>
  </div>
</template>

<script>
import AdaptiveSelectorList from '@/components/AdaptiveSelector/AdaptiveSelectorList'

export default {
  name: 'ProblemsPicker',
  components: { AdaptiveSelectorList },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null,
    }
  },
  created() {
    if (this.options.length) {
      this.selectOption(this.options[0])
    }
  },
  methods: {
    selectOption(event) {
      const option = { ...event }
      this.selectedOption = option
      this.$emit('selectedOption', option)
    },
  },
}
</script>

<style lang="sass" scoped>
.problem-picker
  &__item
    border-radius: 10px
    cursor: pointer
    color: $color-ink-light
    flex-shrink: 0
    font-size: $font-size-s
    font-weight: $font-weight-semi-bold
    +flex-center
    +space-inline($size-xs)
    +transition($speed-x-fast)

    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px $color-primary-light

    &.--active
      background: rgba(192, 217, 246, 0.5)
      color: $color-ink

    &.--disabled
      cursor: not-allowed
      opacity: 0.5

      &:focus
        box-shadow: none
</style>
