<template>
  <header
    class="report-header"
    :style="headerStyle"
  >
    <wrapper>
      <div class="report-header__wrapper">
        <transition name="slide-fade">
          <s-button
            v-if="enableTransition"
            variation="tertiary"
            icon-left="arrow-left"
            @click="$emit('back')"
          >
            {{ $t('commons.back') }}
          </s-button>
        </transition>
        <transition name="up-fade">
          <h4
            v-if="enableTransition"
            class="report-header__subtitle"
          >
            {{ subtitle }}
          </h4>
        </transition>
        <transition name="up-fade">
          <h2 v-if="enableTransition">
            {{ title }}
          </h2>
        </transition>
      </div>
    </wrapper>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import subject from '@/mixins/subject'

export default {
  name: 'ReportHeader',
  mixins: [ subject ],
  data() {
    return {
      enableTransition: false,
    }
  },
  computed: {
    ...mapGetters([
      'report',
    ]),
    headerStyle() {
      if (this.$route.params.extraActivityId) {
        return { 'background-color': '#1F3161' }
      }
      if (this.subject.ready) {
        return { 'background-color': this.subject.data.color }
      }

      return {}
    },
    title() {
      return this.report.data?.title
    },
    subtitle() {
      return this.report.data?.subtitle
    },
  },
  watch: {
    report(report) {
      if (report.ready) {
        this.enableTransition = true
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.report-header
  padding: $size-m 0
  background-color: #e0e4ea
  color: $color-white
  +transition($speed-fast)
  min-height: 181px

  +mq-s--mf
    min-height: 198px

  .slide-fade
    &-enter-active
      transition: all .3s .2s ease

    &-enter
      transform: translateX(10px)
      opacity: 0

  .up-fade
    &-enter-active
      transition: all .3s .4s ease

    &-enter
      transform: translateY(10px)
      opacity: 0

  .sas-button
    &.--tertiary
      color: $color-white

  &__subtitle
    color: transparentize($color-white, 0.2)
    margin-top: $size-l
</style>
