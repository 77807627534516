<template>
  <div class="questions">
    <QuestionsCard
      v-for="(question, index) in questions"
      :key="index"
      :order="index + 1"
      :statement="question.statement"
      :content="question.content"
      :correct="question.correct"
      :active="question === activeQuestion"
      :variation="variation"
      @click="$emit('select', {
        question,
        questionIndex: index
      })"
    />
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import badgeStyle from '@/mixins/badgeStyle'
import QuestionsCard from './QuestionsCard'

export default {
  name: 'Questions',
  components: {
    QuestionsCard,
  },
  mixins: [ mediaQueries, badgeStyle ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    activeQuestion: {
      type: Object,
      default: null,
    },
    variation: {
      type: String,
      default: 'neutral',
    },
  },
}
</script>
