<template>
  <div
    :class="[
      'report',
      {'--center': error},
    ]"
  >
    <template v-if="!error">
      <ReportHeader
        @back="goBack"
      />
      <wrapper :full-width="mq_m">
        <div class="report__body">
          <div class="report__hit-rate">
            <HitRateSkeleton v-if="loading" />
            <HitRate
              v-else-if="report.data"
              :hit-rate="Math.round(report.data.hitPercentage)"
              :correct="report.data.correctAnswers"
              :total="report.data.correctAnswers + report.data.wrongAnswers"
            />
            <div class="report__hit-rate__evaluation align-content-center">
              <SatisfactionSurvey />
            </div>
          </div>
          <section class="report__questions">
            <div
              v-if="questionsWithComplaint.length"
              class="report__questions__section"
            >
              <h4 class="report__questions__title">
                {{ $t('studentReport.problemReported') }}
              </h4>
              <ReportedQuestions
                :questions="questionsWithComplaint"
                :active-question="activeQuestion"
                variation="warning"
                @select="selectQuestion"
              />
            </div>
            <div class="report__questions__section">
              <h4
                v-if="questionsWithComplaint.length"
                class="report__questions__title"
              >
                {{ $t('studentReport.allQuestions') }}
              </h4>
              <QuestionsSkeleton v-if="loading" />
              <Questions
                v-else
                :questions="questions"
                :active-question="activeQuestion"
                @select="selectQuestion"
              />
            </div>
            <div class="report__body__evaluation align-content-center">
              <SatisfactionSurvey />
            </div>
          </section>
        </div>
      </wrapper>
    </template>
    <EmptyState
      v-else
      :image="emptyState.image"
      :title="emptyState.title"
      :description="emptyState.description"
    >
      <s-button
        icon-left="arrow-left"
        @click="goBack"
      >
        {{ $t('commons.back') }}
      </s-button>
    </EmptyState>
    <QuestionDrawer
      v-if="activeQuestion && report.data"
      :question="activeQuestion"
      :assignment-code="report.data.assignmentCode"
      :has-previous="hasPreviousQuestion"
      :has-next="hasNextQuestion"
      @update-reports="fetchQuestions"
      @on-close="clearSelectedQuestion"
      @on-previous="previousQuestion"
      @on-next="nextQuestion"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import mediaQueries from '@/mixins/mediaQueries'
import questions from '@/service/questions'
import EmptyState from '@/components/EmptyState'
import QuestionDrawer from '@/components/QuestionDrawer/QuestionDrawer'
import SatisfactionSurvey from '@/components/SatisfactionSurvey'

import ReportedQuestions from './partials/ReportedQuestions'
import ReportHeader from './partials/ReportHeader'
import HitRate from './partials/HitRate'
import HitRateSkeleton from './partials/HitRateSkeleton'
import Questions from './partials/Questions'
import QuestionsSkeleton from './partials/QuestionsSkeleton'

export default {
  name: 'Report',
  components: {
    EmptyState,
    ReportHeader,
    HitRate,
    HitRateSkeleton,
    Questions,
    QuestionsSkeleton,
    QuestionDrawer,
    ReportedQuestions,
    SatisfactionSurvey,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      answeredQuestions: {
        data: [],
        loading: false,
        error: null,
      },
      activeQuestionIndex: -1,
      reportedQuestionsList: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'report',
    ]),
    loading() {
      return this.report.loading || this.answeredQuestions.loading
    },
    error() {
      return Boolean(
        this.report.error
        || this.answeredQuestions.error
      )
    },
    questionnaireNotFound() {
      return this.report.error?.status === 422
    },
    assignmentCode() {
      return this.report.data?.assignmentCode
    },
    questions() {
      return this.answeredQuestions.data?.map((item, index) => ({
        ...item,
        number: index + 1,
      })) ?? []
    },
    questionsList() {
      return this.reportedQuestionsList
        ? this.questionsWithComplaint
        : this.questions
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion || this.loading) return null

      const [ question ] = this.questionsList.filter((item) => (
        item.id === activeQuestion
      ))

      return question || {}
    },
    hasPreviousQuestion() {
      return this.activeQuestionIndex > 0
      || (Boolean(this.questionsWithComplaint.length)
      && this.questionsList === this.questions)
    },
    hasNextQuestion() {
      return this.activeQuestionIndex < this.questionsList.length - 1
      || this.questionsList === this.questionsWithComplaint
    },
    emptyState() {
      if (this.questionnaireNotFound) {
        return {
          image: 'question-error-404',
          title: this.$t('questions.empty.404.title'),
          description: this.$t('questions.empty.404.description'),
        }
      }

      return {
        image: 'question-error',
        title: this.$t('questions.empty.error.title'),
        description: this.$t('questions.empty.error.description'),
      }
    },
    questionsWithComplaint() {
      return this.questions
        .map((question, index) => ({
          ...question,
          position: index + 1,
          reportedQuestion: true,
        }))
        .filter((question) => question.hasComplaint)
    },
    isSuggestedQuestions() {
      return this.report.data?.type?.toUpperCase() === 'PROPOSED_ACTIVITY'
    },
  },
  async created() {
    await this.fetchReport()
    if (this.report.ready) {
      this.fetchQuestions()
    }
  },
  destroyed() {
    this.clearReport()
  },
  methods: {
    ...mapActions([
      'getReport',
      'finishAssignment',
      'clearReport',
    ]),
    async fetchReport() {
      const { assignmentCode, questionnaireCode } = this.$route.params
      try {
        if (assignmentCode !== undefined) {
          await this.finishAssignment(assignmentCode)
        }
        await this.getReport(questionnaireCode)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchQuestions() {
      this.answeredQuestions.loading = true

      try {
        this.answeredQuestions.data = await questions.fetchAnsweredQuestions(
          this.assignmentCode
        )
      } catch (error) {
        this.answeredQuestions.error = error.response
      } finally {
        this.answeredQuestions.loading = false
      }
    },
    goBack() {
      const { params, query } = this.$route

      if (this.isSuggestedQuestions) {
        this.$router.push({ name: 'proposed-assignment-historic' })
      } else if (params.lectureId) {
        this.$router.push({ name: 'lecture-assignments' })
      } else if (params.extraActivityId) {
        this.$router.push({
          name: 'extra-activity-assignments',
          query,
        })
      }
    },
    selectQuestion({ question, questionIndex }) {
      if (question) {
        this.reportedQuestionsList = Boolean(question.reportedQuestion)
        this.activeQuestionIndex = questionIndex

        if (question.id !== this.$route.query.activeQuestion) {
          const { name, params, query } = this.$route

          this.$router.push({
            name,
            params: { ...params },
            query: {
              ...query,
              activeQuestion: question.id,
            },
          })
        }
      }
    },
    clearSelectedQuestion() {
      const { name, params } = this.$route
      const query = { ...this.$route.query }

      delete query.activeQuestion
      this.$router.push({
        name,
        params: { ...params },
        query,
      })
    },
    previousQuestion() {
      if (
        this.questionsList === this.questions
        && this.questionsWithComplaint.length
        && this.activeQuestionIndex === 0
      ) {
        this.reportedQuestionsList = true
        this.activeQuestionIndex = this.questionsList.length - 1
        this.selectQuestion({
          question: this.questionsList[this.questionsList.length - 1],
          questionIndex: this.questionsList.length - 1,
        })
      } else {
        this.selectQuestion({
          question: this.questionsList[this.activeQuestionIndex - 1],
          questionIndex: this.activeQuestionIndex - 1,
        })
      }
    },
    nextQuestion() {
      if (this.activeQuestionIndex < this.questionsList.length - 1) {
        this.selectQuestion({
          question: this.questionsList[this.activeQuestionIndex + 1],
          questionIndex: this.activeQuestionIndex + 1,
        })
      } else if (this.questionsList === this.questionsWithComplaint) {
        this.reportedQuestionsList = false
        this.activeQuestionIndex = -1
        this.selectQuestion({
          question: this.questionsList[this.activeQuestionIndex + 1],
          questionIndex: this.activeQuestionIndex + 1,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.report
  background-color: $color-ice
  min-height: calc(100vh - #{$menu-height})
  padding-bottom: $end-space

  &.--center
    +flex-column-center

  &__body
    +mq-xl__mf
      display: flex

  &__hit-rate
    margin: $size-m 0
    align-items: center

    +mq-xl__mf
      margin-right: $size-m

  &__questions
    padding: 0 $size-m $size-m
    flex-grow: 1

    +mq-m__mf
      padding-left: 0
      padding-right: 0

    +mq-xl__mf
      margin-top: $size-m

    &__section
      margin-bottom: $size-l

    &__title
      margin-bottom: $size-m

.align-content-center
  width: 100%
  display: flex
  justify-content: center

.report__hit-rate__evaluation
  margin: $size-m 0
  display: none

  +mq-xl--mf
    display: flex

.report__body__evaluation
  +mq_xl--mf
    display: none
</style>
